const Color = {
  dourado: "#d71921",
  douradoDarker: "#9D6826",
  cinzaLighter: "#ADADAD",
  cinzaLight: "#686868",
  cinza: "#4F4F4F",
  cinzaDark: "#414042",
  preto: "#1B1B1B"
};

export default Color;