import styled from "styled-components";
import Color from "../../styles/colors";
import Metric from "../../styles/metrics";
import 'antd/dist/antd.css';

export const HeaderWrapper = styled.header``;

export const Nav = styled.nav`
  /* display: none; */
  height: 140px;
  width: 100%;
  background-color: white;
  position: relative;

  .upper {
    border-bottom: 1px solid ${Color.cinzaLighter};
    height: 32.5px;
    width: 100%;

    .content {
      max-width: 1200px;
      margin: 0 auto;
      display: grid;
      height: 32.5px;
      grid-template-columns: 11fr 1fr;
      align-items: center;
    }

    .left {
      span {
        color: ${Color.cinzaLight};
        font-size: ${Metric.fontSize * 0.65}px;
        font-family: "Volte2";
        justify-self: center;
        margin-right: 30px;
        strong {
          font-family: "Volte3";
        }
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 30px;

      a {
          margin-left: 5px;
          transition: 0.5s ease;
          &:first-child{
            margin-left: 0;
          }
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
  .main {
    display: grid;
    grid-template-columns: 1.6fr 11fr;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    height: 105px;
    align-items: center;
    .menu-icon {
      display: none;
    }
    .links {
      ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        height: 110px;

        li {
          margin: 0 15px;
          color: ${Color.cinzaDark};
          font-family: "Volte2";
          position: relative;
          transition: 0.5s ease;

          &.active:after,
          &:not(.button):after {
            width: 0%;
            height: 2px;
            content: "";
            background-color: ${Color.dourado};
            border-radius: 3px;
            position: absolute;
            bottom: -3px;
            transition: 0.5s ease;
            left: 0;
          }

          &.active,
          &:hover {
            color: ${Color.dourado};
            &:after {
              width: 100%;
            }
          }
        }
        a {
          color: ${Color.preto};
          &.button {
            margin: 0 15px;
            color: ${Color.dourado};
            font-family: "Volte2";
            position: relative;
            transition: 0.5s ease;
            padding: 10px 25px;
            border-radius: 30px;
            border: 1px solid ${Color.dourado};
            transition: 0.5s ease;

            svg {
              font-size: 17px;
              margin-bottom: -4px;
            }

            &:hover {
              background-color: ${Color.dourado};
              color: white;
              border-color: ${Color.dourado};

              svg {
                fill: #fff;
              }
            }
          }
          &.hide{
            display:none;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    height: 70px;
    .upper {
      display: none;
    }
    .main {
      padding-top: 10px;
      width: 95%;
      justify-content: space-between;
      display: flex;
      height: 70px;

      .menu-icon {
        display: flex;
        align-items: center;
        font-size: 20px;
        padding: 0 30px;
      }
      img {
        height: 40px;
        padding-left: 10px;
      }
      .links {
        display: none;
      }
    }
  }
`;

export const NavFixed = styled.nav`
  height: 80px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid ${Color.cinzaLighter};
  position: fixed;
  z-index: 99;
  top: ${props => (props.isNavFixed ? "0" : "-200px")};
  transition: 0.5s ease;
  .menu-icon {
    display: none;
  }

  img {
    max-width: 130px;
    height: 70px;
  }

  display: grid;
  grid-template-columns: 1fr 11fr;

  padding: 5px 10%;

  .links {
    ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      height: 70px;

      li {
        margin: 0 10px;
        color: ${Color.cinzaDark};
        font-family: "Volte2";
        transition: 0.5s ease;
        position: relative;

        &:not(.button):after {
          width: 0%;
          height: 2px;
          content: "";
          background-color: ${Color.dourado};
          border-radius: 3px;
          position: absolute;
          bottom: -3px;
          transition: 0.5s ease;
          left: 0;
        }

        &.active,
        &:hover {
          color: ${Color.dourado};
          &:after {
            width: 100%;
          }
        }
      }
      a {
        color: ${Color.preto};
        &.button {
          margin: 0 5px;
          color: ${Color.dourado};
          font-family: "Volte2";
          transition: 0.5s ease;
          position: relative;
          padding: 10px 15px;
          border-radius: 30px;
          border: 1px solid ${Color.dourado};
          transition: 0.5s ease;
          &:hover {
            background-color: ${Color.dourado};
            color: white;
            border-color: ${Color.dourado};
          }
        }

        &.hide{
            display:none;
          }
      }
    }
  }

  @media (max-width: 480px) {
    height: 60px;
    padding: 10px 2.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .menu-icon {
      display: flex;
      padding: 0 20px;
    }
    img {
      max-height: 40px;
    }
    .links {
      display: none;
    }
  }
`;

export const MenuMobile = styled.div`
  width: 90%;
  position: fixed;
  top: 0;
  transition: all 1s ease;
  /* transition-delay: 0.3s; */
  left: ${prop => (prop.isMenuToggled ? "0" : "-100vw")};
  z-index: 999;
  height: 100vh;

  .menu-wrapper {
    position: absolute;
    background-color: white;
    box-shadow: -4px 2px 5px 0px rgba(0, 0, 0, 0.35);
    z-index: 5;
    height: 100%;
    width: 100%;
    transition-delay: 1s ease;
    transition: 0.5s ease;
    left: ${prop => (prop.isMenuToggled ? "0" : "-100vw")};
    display: flex;
    flex-direction: column;
    padding: 30px 5%;

    .top {
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .menu-icon {
        font-size: 20px;
        /* padding: 0 20px; */
      }
    }
    .logo {
      height: 45px;
      display: block;
      margin: 15px auto 40px auto;
    }
    .links {
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        
        li {
          a {
            color: ${Color.preto};
            font-size: 22px;
          }
          margin-bottom: 27.5px;
        }
        a.button {
          margin: 0 auto 27.5px auto;
          color: ${Color.dourado};
          font-family: "Volte2";
          position: relative;
          transition: 0.5s ease;
          padding: 10px 25px;
          border-radius: 30px;
          border: 1px solid ${Color.dourado};
          transition: 0.5s ease;

          svg {
            font-size: 17px;
            margin-bottom: -4px;
          }

          &:hover {
            background-color: ${Color.dourado};
            color: white;
            border-color: ${Color.dourado};

            svg {
              fill: #fff;
            }
          }
        }
      }
    }
  }
  .backdrop {
    width: 80%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.35);
    width: 100%;
    height: 100vh;
    z-index: 0;
    transition: ${prop => (prop.isMenuToggled ? "5s ease-in" : "0 ease")};
    opacity: ${prop => (prop.isMenuToggled ? "1" : "0")};
  }
`;
