import React, { useEffect, useState } from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import api from "../../services/api";

import { Container } from "./styles";

export default function SingleDoctor({ match }) {
  const [content, setContent] = useState(null);
  const medico = match.params.slug


  useEffect(() => {
    const getContent = async () => {
      const { data } = await api.get(`wp/v2/medicos?slug=${medico}`)
      setContent(data);
    }
    getContent();

  }, [])

  return (
    <>
      <Header />
      <Container>
        {!!content && content.map((obj) => (
          <div key={Math.random()} className="top-text">
            <h1>Doutor(a)</h1>
            <p>{obj.title.rendered}</p>
          </div>
        ))}
        {!!content && content.map((obj) => (
          <div key={Math.random()} className="doctor-info">
            <img
              src={obj.acf.foto.url}
              alt="Doutor"
            />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              egestas ac est quis iaculis. Ut id orci rutrum arcu vestibulum
              scelerisque sit amet et felis. Vestibulum ante ipsum primis in
              faucibus orci luctus et ultrices posuere cubilia Curae; Quisque ac
              viverra magna, eget bibendum eros. Donec finibus ullamcorper dictum.
              Nulla condimentum, sapien vitae aliquet lacinia, odio lorem lobortis
              lectus, sed sodales erat metus vitae justo. Quisque ac cursus
              tellus. Phasellus lorem velit, fermentum quis vulputate a, rhoncus
              sit amet enim. Maecenas vitae accumsan magna. Proin iaculis
              porttitor sem, sit amet interdum nunc eleifend sed. Duis id mollis
              felis, vitae facilisis sapien. Mauris laoreet molestie risus,
              tristique condimentum justo iaculis id. Sed efficitur suscipit odio,
              a vestibulum metus ullamcorper sit amet. Integer quis lacus orci.
              Duis condimentum ante ac viverra lacinia. Class aptent taciti
              sociosqu ad litora torquent per conubia nostra, per inceptos
              himenaeos. In convallis lorem id dolor volutpat pharetra. Duis
              tincidunt volutpat justo, ut vehicula lacus sodales eu. Aenean
              interdum leo ut mauris feugiat facilisis. Nam libero augue, rhoncus
              eget dictum euismod, venenatis a quam. Duis quis gravida nisl. Duis
              condimentum mauris sed est volutpat egestas. Duis a rutrum nisi, ut
              auctor diam. Nunc et urna eu nisl blandit accumsan id non arcu.
          </p>
          </div>
        ))}
      </Container>
      <Footer />
    </>
  );
}
