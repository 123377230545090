import styled from "styled-components";
import Color from "../../styles/colors";
import Metric from "../../styles/metrics";

import wireframe from "../../assets/img/wireframe.svg";

export const Container = styled.div`
  width: 65%;
  max-width: 1280px;
  margin: auto;

  @media (max-width: 480px) {
    width: 90%;
  }
`;

export const SobreMainWrapper = styled.section`
  background-image: linear-gradient(-180deg, #686868 0%, #d71921 37%);
  padding: ${Metric.base * 10}px 0;
  min-height: 1200px;
  h2 {
    font-family: "Volte2";
    font-size: ${Metric.fontSize * 2.5}px;
    text-align: center;
    color: white;
  }

  .texto {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 80px;

    h3 {
      color: white;
      font-family: "Volte2";
      font-size: ${Metric.fontSize * 1.5}px;
      text-align: left;
    }
    p {
      color: white;
      font-size: ${Metric.fontSize * 1}px;
      line-height: ${Metric.fontSize * 1.5}px;
      font-family: "Volte2";
    }
  }

  @media (max-width: 480px) {
    h2 {
      font-size: ${Metric.fontSize * 2}px;
    }
    .texto {
      grid-template-columns: 1fr;
      h3 {
        margin-bottom: 20px;
      }
    }
  }

  .city-sobre {
    background: brown;
    height: 600px;
    margin-top: 50px;
    border-radius: 20px;

    @media screen and (max-width: 480px) {
      height: 809px;
    }

    .content {
      display: grid;
      padding: 50px;

      @media screen and (max-width: 480px) {
        grid-template-columns: inherit;
        padding: 30px;
      }
    }
    h1 {
      color: white;
      font-size: 50px;
      font-weight: bold;
    }

    p {
      margin: 0 0 auto auto;
      text-align: left;
      line-height: ${Metric.fontSize * 1.5}px;
      color: white;
      width: 100%;
      font-size: ${Metric.fontSize * 1}px;
    }
  }
`;

export const SobreMeritos = styled.section`
  padding: ${Metric.base * 16}px 0;

  .icons {
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: ${Metric.base * 7.5}px;
    align-items: center;
    justify-content: space-between;

    .icon {
      display: flex;
      flex-direction: column;
      align-content: center;
      img {
        margin-bottom: ${Metric.base * 3}px;
      }
      h4 {
        color: white;
        text-align: center;
        padding: 0 15%;
        font-family: "Volte3";
        font-size: ${Metric.fontSize * 1}px;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 50px 0;
    .icons {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 20px;

      .icon {
        img {
          max-height: 100px;
          margin-bottom: 10px;
        }
        h4 {
          font-size: ${Metric.fontSize}px;
        }
      }
    }
  }
`;

export const Equipe = styled.section`
  background-color: white;
  background-image: url('${wireframe}');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  padding: ${Metric.base * 10}px 0;

  h2{
    color: ${Color.dourado};
    font-family: "Volte2";
    text-align: center;
    font-size: 40px;

  }

  .membros{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    margin: 100px 0;
    grid-gap: 10px;
  }

  @media (max-width: 480px){
    .membros{
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      
    }
  }
`;

export const Membro = styled.div`
  
  .holder{
    position: relative;
  }
  .img{
    opacity: 1;
    transition: .5s ease;
    background-size:cover;
    background-position: center;
    background-image: url('${props => props.image}');
    height: 400px;
    border-radius: 20px;
    cursor: pointer;
  }

  .holder:hover .middle{
    opacity: 1;
  }

  .holder:hover .img{
    opacity: 0.4;
  }

  .middle{
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #d71921;
    padding: 10px 10px;
    border-radius: 15px;
    cursor: pointer;
    a{
      font-size: 14px;
      color: white;
      font-weight: bold;
    }
  }

  .info{
    padding: 30px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .nome{
      font-size: 20px;
      font-family: "Volte3";
      color: ${Color.preto};
    }
    .cargo{
      font-family: "Volte2";
      color: ${Color.dourado};
    }
  }

  @media (max-width: 480px){
    .img{
      height: 250px;

    }
    .info{
      padding: 15px 0;
    }
  }
`;
