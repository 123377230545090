import React from "react";

import { Switch, Route } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import Home from "../pages/Home";
import Sobre from "../pages/Sobre";
import Exame from "../pages/Exame";
import Agendar from "../pages/Agendar";
import JunteSe from "../pages/JunteSe";
import Calculadora from "../pages/Calculadora";
import SingleDoctor from "../pages/SingleDoctor";

const Routes = () => (
  <Switch>
    <ScrollToTop>
      <Route exact path="/" component={Home} />
      <Route exact path="/sobre" component={Sobre} />
      <Route path="/exame/:slug" component={Exame} />
      <Route exact path="/agendar" component={Agendar} />
      <Route exact path="/agendar/:exame" component={Agendar} />
      <Route exact path="/junte-se-a-nos" component={JunteSe} />
      <Route exact path="/calculadora-de-gestacao" component={Calculadora} />
      <Route exact path="/medicos/:slug" component={SingleDoctor} />
    </ScrollToTop>
  </Switch>
);

export default Routes;
