import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SmoothScroll from "smooth-scroll";

import api from "../../services/api";

import {
  Container,
  SobreMainWrapper,
  SobreMeritos,
  Equipe,
  Membro
} from "./styles";

import { Loader } from "../../styles/global";

import icon_21anos from "../../assets/img/icon_21anos.svg";
import icon_resultados from "../../assets/img/icon_resultados.svg";
import icon_corpoClinico from "../../assets/img/icon_corpoClinico.svg";
import icon_equipamentos from "../../assets/img/icon_equipamentos.svg";

const Sobre = props => {
  const [membros, setMembros] = useState();
  const [conteudo, setConteudo] = useState();
  const [aboutCity, setAboutCity] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Sobre Nós - MKClinic";
    getSobreContent().then(() => {
      setLoading(false);

      if (props.location.hash) {
        var scroll = new SmoothScroll();
        const hash = props.location.hash;
        var anchor = document.querySelector(hash);
        scroll.animateScroll(anchor);
      }
    });
  }, [props.location.hash]);

  useEffect(() => {

    if (props.location.hash) {
      var scroll = new SmoothScroll();
      const hash = props.location.hash;
      var anchor = document.querySelector(hash);
      scroll.animateScroll(anchor);
    }
  }, [props.location.hash]);

  const getSobreContent = async () => {
    const response = await api.get("wp/v2/pages?slug=sobre");
    if (response.data) {
      setConteudo(response.data[0].acf.descricao);
      setAboutCity(response.data[0].acf);
    }

    const med = await api.get("wp/v2/medicos");
    if (med.data) {
      setMembros(med.data);
    }
  };

  return (
    <>
      <Header />

      <SobreMainWrapper id="sobre">
        {loading ? (
          <Loader color="white" size={100} />
        ) : (
            <Container>
              <h2>
                A MK Clinic é um centro especializado em ultrassonografia que
                disponibiliza os melhores equipamentos da região.
            </h2>
              <SobreMeritos>
                <div className="icons">
                  <div className="icon">
                    <img src={icon_21anos} alt="" />
                    <h4>21 Anos de Experiência</h4>
                  </div>
                  <div className="icon">
                    <img src={icon_equipamentos} alt="" />
                    <h4>Equipamentos Modernos</h4>
                  </div>
                  <div className="icon">
                    <img src={icon_corpoClinico} alt="" />
                    <h4>Corpo-clínico qualificado</h4>
                  </div>
                  <div className="icon">
                    <img src={icon_resultados} alt="" />
                    <h4>Resultados Precisos</h4>
                  </div>
                </div>
              </SobreMeritos>
              <div className="texto">
                <h3>{conteudo.titulo}</h3>
                <div dangerouslySetInnerHTML={{ __html: conteudo.texto }} />
              </div>
              <div className="city-sobre">
                <div className="content">
                  <h1>Ponta Grossa</h1>
                  <div dangerouslySetInnerHTML={{ __html: aboutCity.ponta_grossa }} />
                </div>
              </div>
              <div className="city-sobre">
                <div className="content">
                  <h1>Jaguariaíva</h1>
                  <div dangerouslySetInnerHTML={{ __html: aboutCity.jaguariaiva }} />
                </div>
              </div>
            </Container>
          )}
      </SobreMainWrapper>
      <Equipe id="corpoClinico">
        <Container>
          <h2>Conheça a Equipe</h2>
          {loading ? (
            <Loader color="black" size={100} />
          ) : (
              <div className="membros">
                {!!membros
                  ? membros.map((membro, index) => (
                    <Membro
                      key={index}
                      image={
                        membro.acf.foto.url
                      }
                    >
                      <div className="holder">
                        <div className="img" />
                        <div className="middle">
                          <a href={`/medicos/${membro.slug}`}>Ver sobre</a>
                        </div>
                      </div>
                      <div className="info">
                        <span className="nome">{membro.title.rendered}</span>
                        {!!membro.cargo ? (
                          <span className="cargo">{membro.cargo}</span>
                        ) : (
                            ""
                          )}
                        {!!membro.crm ? (
                          <span className="cargo">CRM: {membro.crm}</span>
                        ) : (
                            ""
                          )}
                      </div>
                    </Membro>
                  ))
                  : ""}
              </div>
            )}
        </Container>
      </Equipe>

      <Footer darker={false} />
    </>
  );
};

export default Sobre;
