import styled from "styled-components"
import Color from "../../styles/colors"
import Metric from "../../styles/metrics"

import { Form, Input, Select, FileInput } from "@rocketseat/unform"

import UnformMaskedInput from "../../components/UnformMaskedInput"

export const Container = styled.div`
  max-width: 1280px;
  width: 65%;
  margin: 0 auto;

  @media (max-width: 480px) {
    width: 90%;
  }
`

export const AgendarWrapper = styled.section`
  background-color: #f8f8f8;
  padding: 130px 0;

  h1 {
    font-family: "Volte2";
    font-size: ${Metric.fontSize * 1.5}px;
    color: ${Color.cinzaLight};
    margin-bottom: 65px;
  }
  .btnEnviar {
    width: 100%;
    background-color: ${Color.dourado};
    color: white;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Volte3";
    transition: 0.5s ease;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    margin-top: 50px;

    &:hover {
      background-color: ${Color.preto};
    }
  }

  @media (max-width: 480px) {
    padding: 50px 0;

    h1 {
      text-align: center;
    }
  }
`

export const FormWrapper = styled(Form)`
  label {
    color: ${Color.cinzaLighter};
    font-weight: 600;
    display: block;
  }
  .row {
    display: grid;
    align-items: center;
    margin-bottom: 30px;
    &.col-2 {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 40px;
    }
    &.col-3 {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 40px;
    }
  }

  .formMessage{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; 
    height: 50px;
    width: 100%;
    font-family: "Volte3";
    border-radius: 40px;
    margin: 30px 0;
    &.error{
      background-color: red;
      color: white;
    }

  }
  .exames{
    margin-bottom: 40px;
    min-height: 200px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    select{
      margin: 20px 0;
      border-radius: 30px;   
    }
    label{
      margin-bottom: 20px;
    }
    .btnAdicionar{
      align-items: center;
      background-color: ${Color.dourado};
      border-radius: 70px;
      height: 50px;
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      color: white;
      font-family: "Volte2";
      font-size: ${Metric.fontSize * 0.8}px;
      border: none;
      transition: 0.5s ease;
      cursor: pointer;
      width: 200px;

      &:hover {
        background-color: ${Color.douradoDarker};
      }
    }

    .listSelecionados{
      display: block;
      .examesSelecionados{
        li{
          display: inline-flex;
          padding: 10px 15px;
          background-color: ${Color.cinzaDark};
          color: white;
          font-family: "Volte3";
          border-radius: 25px;
          align-items: center;
          margin: 5px;
          svg{
            cursor: pointer;
            border-radius: 100%;
            background-color: white;
            color: black;
            margin-left: 5px;
            transition: .5s ease;
            &:hover{
              background-color: ${Color.dourado};
              color: white;
            }
          }

        }

      }
    }
  }
  .fileInput {
    label {
      align-items: center;
      background-color: ${Color.dourado};
      border-radius: 70px;
      height: 50px;
      display: flex;
      justify-content: center;
      color: white;
      font-family: "Volte2";
      font-size: ${Metric.fontSize * 0.8}px;
      border: none;
      transition: 0.5s ease;
      cursor: pointer;
      &:hover {
        background-color: ${Color.douradoDarker};
      }
      i {
        margin-right: 30px;
      }
    }
  }
  .dataHora {
    display: grid;
    grid-template-columns: 500px .9fr ;
    margin-top: 60px;
    grid-column-gap: 35px;

    .data {
      .react-datepicker {
        height: 300px;
        width: 100%;
        margin: 20px 0;
        border: none;
        box-shadow: 0 0 15px rgba(0, 0, 0, .2);
        font-family: "Helvetica";
        font-size: 15px;

        .react-datepicker__navigation {
          transition: 0.5s ease;

          &.react-datepicker__navigation--next {
            top: 35px;
            right: 30px;
            &:hover {
              border-left-color: ${Color.preto};
            }
          }

          &.react-datepicker__navigation--previous {
            top: 35px;
            right: 75px;
            left: auto;
            &:hover {
              border-right-color: ${Color.preto};
            }
          }
        }
        .react-datepicker__month-container {
          padding: 25px;
          width: 100%;
          .react-datepicker__header {
            background-color: white;
            border: none;
            .react-datepicker__current-month {
              text-align: left;
              color: ${Color.cinzaLight};
              padding-left: 0.4rem;
            }
            .react-datepicker__day-names {
              display: flex;
              justify-content: space-between;
              margin-top: 25px;
              /* color: ${Color.cinzaLighter}; */
              .react-datepicker__day-name {
                font-weight: 700;
                color: ${Color.cinzaLighter};
              }
            }
          }

          .react-datepicker__month {
            margin: 0;
            .react-datepicker__week {
              display: flex;
              color: #7C86A2;
              justify-content: space-between;
            }
          }
        }
      }
    }

    .hora {
      label{
        margin-bottom: 35px;
      }

      .horaWrapper{
        margin-bottom: 30px;
        background-color: white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        height: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        input{
          width: 100%;
          border: none;
          padding-left: ${Metric.fontSize * 2}px;
          height: 60px;
          color: ${Color.cinza}
          font-size: ${Metric.fontSize * 1.2}px;
          font-family: "Volte3";
          display: flex;
          text-align: center;
          justify-content: center;
          letter-spacing: ${Metric.fontSize * 2}px;
        }
      }
    }
  }

  @media (max-width: 480px){
    .row{
      &.col-2, &.col-3{
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
      }
    }

    .exames{
      grid-template-columns: 1fr;
      grid-row-gap: 30px;
      button{
        width: 100%;
      }
    }
    .dataHora{
      grid-template-columns: 1fr;
    }
  }
`

export const UnformInput = styled(Input)`
  width: 100%;
  height: 50px;
  background: none !important;
  border: none;
  border-bottom: 2px solid ${Color.cinzaLighter};
  color: ${Color.cinzaDark};
  font-family: "Volte2";

  &.observacoesInput {
    height: 130px;
  }
`

export const UnformSelect = styled(Select)`
  background-color: white;
  border: none;
  border-radius: 20px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 60px;
  color: ${Color.cinza};
  font-family: "Volte2";
`

export const StyledUnformMaskedInput = styled(UnformMaskedInput)`
  &.defaultInput {
    width: 100%;
    height: 50px;
    background: none !important;
    border: none;
    border-bottom: 2px solid ${Color.cinzaLighter};
    color: ${Color.cinzaDark};
    font-family: "Volte2";
  }
`

export const UnformFileInput = styled(FileInput)`
  display: none;
`
