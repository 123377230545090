import React, { useState, useEffect } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import SmoothScroll from "smooth-scroll";
import Slider from "react-slick";

import api from "../../services/api";
import * as Yup from "yup";
import ademail from "../../services/ademail";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BannerSlider from "../../components/BannerSlider";
import SobreTabs from "../../components/SobreTabs";
import Exames from "../../components/Exames";

import { Loader } from "../../styles/global";

import {
  Container,
  Sobre,
  Meritos,
  CTA,
  Convenios,
  Contato,
  Unform,
  UnformTextInput,
  IconConvenio
} from "./styles";

import icon_21anos_fill from "../../assets/img/icon_21anos_fill.svg";
import icon_resultados_fill from "../../assets/img/icon_resultados_fill.svg";
import icon_corpoClinico_fill from "../../assets/img/icon_corpoClinico_fill.svg";
import icon_equipamentos_fill from "../../assets/img/icon_equipamentos_fill.svg";

const Home = props => {
  const [exames, setExames] = useState();
  const [conteudo, setConteudo] = useState();
  const [loading, setLoading] = useState();
  const [banners, setBanners] = useState();
  const [convenios, setConvenios] = useState();
  const [formMessage, setFormMessage] = useState("Enviar");

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    rows: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          rows: 3,
          slidesToScroll: 2,
          infinite: true,
        }
      }
    ]
  };

  const getConvenios = async () => {
    const response = await api.get("/acf/v3/options/convenios");
    if (response.data) {
      setConvenios(response.data.acf.convenios);
    }
  };

  const getHomeContent = async () => {
    const response = await api.get("wp/v2/pages/");
    if (response.data) {
      setConteudo(response.data.acf);
    }
  };

  const getExames = async () => {
    const response = await api.get("/wp/v2/exames?per_page=100&order=asc&orderby=title");
    if (response.data) {
      setExames(response.data);
    }
  };

  const getBanners = async () => {
    const response = await api.get("/acf/v3/options/banners");
    if (response.data) {
      setBanners(response.data.acf.banners);
    }
  };

  useEffect(() => {
    document.title = "MKClinic";

    setLoading(true);

    getBanners();
    getExames();
    getConvenios();
    getHomeContent().then(() => {
      setLoading(false);

      if (props.location.hash) {
        console.log(props.location.hash);
        var scroll = new SmoothScroll();
        const hash = props.location.hash;
        var anchor = document.querySelector(hash);
        scroll.animateScroll(anchor);
      }
    });
  }, [props.location.hash]);

  useEffect(() => {
    if (window.innerWidth <= 480) {
    }

    if (props.location.hash) {
      console.log(props.location.hash);
      var scroll = new SmoothScroll();
      const hash = props.location.hash;
      var anchor = document.querySelector(hash);
      scroll.animateScroll(anchor);
    }
  }, [props.location.hash]);

  const schema = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório"),
    telefone: Yup.string().required("Campo obrigatório"),
    mensagem: Yup.string().required("Campo obrigatório"),
    email: Yup.string()
      .email("Insira um endereço de e-mail válido")
      .required("Campo obrigatório")
  });

  const handleFormSubmit = async data => {
    const body = `<p>Nome: ${data.nome}</p>
        <p>Email: ${data.email}</p>
        <p>Telefone: ${data.telefone}</p>
        <p>Mensagem: ${data.mensagem}</p>`;

    const mail = {
      to: "mkclinicadm@gmail.com",
      from: data.email,
      as: data.nome,
      subject: "Novo Contato - MkClinic",
      message: body
    };
    try {
      const response = await ademail.post("/email", mail);
      if (response) {
        setFormMessage("Enviado!");
      }
    } catch (err) {
      setFormMessage("Algo deu errado. ");
    }
  };

  return (
    <>
      <Header />
      {loading ? (
        <Loader size={100} color="#414042" />
      ) : (
          <>
            <BannerSlider banners={banners} />
            <Sobre>
              <Container id="tabs">
                <SobreTabs />
              </Container>
            </Sobre>
            <Meritos>
              <div className="icons">
                <div className="icon">
                  <img src={icon_21anos_fill} alt="" />
                  <h4>21 Anos de Experiência</h4>
                </div>
                <div className="icon">
                  <img src={icon_equipamentos_fill} alt="" />
                  <h4>Equipamentos Modernos</h4>
                </div>
                <div className="icon">
                  <img src={icon_corpoClinico_fill} alt="" />
                  <h4>Corpo-clínico qualificado</h4>
                </div>
                <div className="icon">
                  <img src={icon_resultados_fill} alt="" />
                  <h4>Resultados Precisos</h4>
                </div>
              </div>
            </Meritos>
            <section id="exames">{!!exames && <Exames data={exames} />}</section>
            <CTA>
              <h3>ESPAÇO MK PRIME</h3>
              <div className="center-content">
                <div className="grid-content">
                  <div className="block">
                    <img src={require('../../assets/img/mk_01.jpg')} alt="imagem"></img>
                  </div>
                  <div className="block">
                    <img src={require('../../assets/img/mk_02.jpg')} alt="imagem"></img>
                  </div>
                  <div className="block">
                    <img src={require('../../assets/img/mk_03.jpg')} alt="imagem"></img>
                  </div>
                  <div className="block">
                    <img src={require('../../assets/img/mk_04.jpg')} alt="imagem"></img>
                  </div>
                </div>
                <p>A saúde e o bem-estar são conceitos distintos que merecem atenção. Cuidar de sua rotina, bem como estar sempre atento à saúde, está diretamente ligado a promover a ausência de doenças e satisfação com a vida. Portanto, assegurar uma vida saudável e promover o bem-estar para todos, em todas as idades, faz parte de nossos princípios.
Pensando nisso, temos uma unidade exclusiva, planejada e alto padrão, dedicada a cuidar da sua saúde com excelência e qualidade. 
Conheça nosso espaço.</p>
              </div>
              {/* <a href="/mkprime">Veja Mais</a> */}
            </CTA>
            {/* <Blog>
            <Container>
              <h2>Blog</h2>
              <div className='posts'>
                <Link to='/'>
                  <Post thumb={img_blog1}>
                    <div className='image' />
                    <div className='info'>
                      <h5>Comer é um ato político em todos os seus aspectos</h5>
                      <p>
                        Nossas escolhas alimentares nunca foram tão carregadas
                        de sentido como agora, segundo uma nutricionista.
                      </p>
                      <span>
                        Ver Mais <i className='fa fa-arrow-right' />
                      </span>
                    </div>
                  </Post>
                </Link>
                <Link to='/'>
                  <Post thumb={img_blog2}>
                    <div className='image' />
                    <div className='info'>
                      <h5>O coração no centro das metas mundiais de saúde</h5>
                      <p>
                        A OMS pede mais atenção com as doenças cardiovasculares.
                        Nosso especialista traz o contexto desse anúncio e suas
                        repercussões.
                      </p>
                      <span>
                        Ver Mais <i className='fa fa-arrow-right' />
                      </span>
                    </div>
                  </Post>
                </Link>
                <Link to='/'>
                  <Post thumb={img_blog3}>
                    <div className='image' />
                    <div className='info'>
                      <h5>
                        Os antibióticos do futuro estão nos livros do passado
                      </h5>
                      <p>
                        Período medieval pode trazer soluções para um dos
                        maiores problemas mundiais de saúde pública.
                      </p>
                      <span>
                        Ver Mais <i className='fa fa-arrow-right' />
                      </span>
                    </div>
                  </Post>
                </Link>
              </div>
              <div className='rowVerTodos'>
                <Link to=''>Ver mais postagens</Link>
              </div>
            </Container>
          </Blog> */}
            <Convenios id="convenios">
              <Container>
                <h2>Convênios</h2>
                <Slider {...settings}>
                  {!!convenios && convenios.map((convenio, index) => (
                    <IconConvenio key={Math.random()}>
                      <img src={convenio.logo.sizes.medium} alt={convenio.nome} />
                    </IconConvenio>
                  ))}
                </Slider>
              </Container>
            </Convenios>
            <Contato id="contato">
              <Container>
                <div className="cols">
                  <Unform schema={schema} onSubmit={handleFormSubmit}>
                    <h2>Entre em contato</h2>
                    <div className="input-field">
                      <UnformTextInput name="nome" label="Seu Nome" />
                    </div>
                    <div className="input-field">
                      <UnformTextInput name="email" label="Seu E-mail" />
                    </div>
                    <div className="input-field">
                      <UnformTextInput name="telefone" label="Seu Telefone" />
                    </div>
                    <div className="input-field">
                      <UnformTextInput
                        name="mensagem"
                        className="multiline"
                        label="Sua Mensagem"
                        multiline
                      />
                    </div>

                    <button type="submit">{formMessage}</button>
                  </Unform>
                </div>
              </Container>
            </Contato>
            <Footer darker={true} />
          </>
        )}
    </>
  );
};

export default Home;
