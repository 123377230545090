import styled from "styled-components";
import Color from "../../styles/colors";
import Metric from "../../styles/metrics";
import "react-image-gallery/styles/css/image-gallery.css";
export const Container = styled.div`
  width: 65%;
  max-width: 1280px;
  margin: auto;

  @media (max-width: 480px) {
    width: 90%;
  }
`;

export const ExameWrapper = styled.section`
  background-color: #f8f8f8;
  padding: 130px 0;
  min-height: 600px;

  .image-gallery{
    margin-bottom: 3rem;
  }

  .image-gallery-icon:hover {
    color: #d71920;
  }

  .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus{
    border: 0px solid transparent;
  }
  .row {
    display: grid;
    grid-column-gap: 50px;
    margin-bottom: 100px;

    .images{
      display: flex;
      img{
        margin-top: 20px;
      }
    }

    h1,
    h2,
    h3 {
      font-family: "Volte2";
      font-size: ${Metric.fontSize * 1.5}px;
      color: ${Color.cinzaLight};
    }
    p {
      text-align: justify;
      font-size: ${Metric.fontSize}px;
      font-family: "Volte2";
      color: ${Color.cinzaLighter};
      line-height: 30px;
    }

    ul {
      li {
        font-size: ${Metric.fontSize}px;
        font-family: "Volte2";
        color: ${Color.cinzaLighter};
        line-height: 30px;
      }
    }
  }
  a {
    width: 100%;
    background: #25d366;

    color: white;
    font-family: "Volte3";
    font-size: ${Metric.fontSize * 0.8}px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 40px;
    transition: 0.5s ease;

    &:hover {
      background: #48d57d;
      color: #fafafa;
    }

    &:active{
      color: #fafafa;
    }

    &:focus{
      color: #fafafa;
    }

    svg{
      margin: 0 0 0 0.5rem;
    }

  }

  @media (max-width: 480px) {
    padding: 50px 0;
    .row {
      grid-template-columns: 1fr;
    }
  }
`;
