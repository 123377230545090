import styled from "styled-components";
import Color from "../../styles/colors";
import Metric from "../../styles/metrics";

export const SobreTabsWrapper = styled.div`
  .items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 15px;
    justify-content: space-between;
    margin-bottom: ${Metric.base * 10.1}px;
    .item {
      border: 2px solid ${Color.dourado};
      border-radius: ${Metric.borderRadius}px;
      height: 200px;
      width: 200px;
      padding: 35px;
      justify-self: stretch;
      position: relative;
      text-align: center;
      transition: 0.5s ease;
      cursor: pointer;
      .icon {
        position: relative;
        margin-bottom: 33px;
        text-align: center;

        @media (max-width: 480px) {
          margin-bottom: 30px;
        }
        img {
          height: 55px;
          max-width: 55px;
        }
        &:after {
          position: absolute;
          content: "";
          left: 38px;
          bottom: -18px;
          width: 48px;
          height: 3px;
          background-color: ${Color.dourado};

          @media (max-width: 480px) {
            bottom: -15px;
          }
        }
      }

      h4 {
        color: ${Color.cinzaLight};
        font-family: "Volte3";
        /* position: absolute; */
        bottom: ${Metric.base * 1}px;
      }
      &.active,
      &:hover {
        background-color: ${Color.dourado};

        .icon {
          filter: brightness(10);
        }
        h4 {
          color: white;
        }
      }
    }
  }
  .texto {
    &.active {
      display: grid !important;
      opacity: 1;
    }
    display: none;
    opacity: 0;
    transition: opacity 1s ease;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 80px;
    font-family: "Volte2";

    h3 {
      font-size: ${Metric.fontSize * 1.5}px;
      color: ${Color.cinzaLight};
      line-height: ${Metric.fontSize * 1.5}px;
    }
    p {
      color: ${Color.cinzaLighter};

      font-size: ${Metric.fontSize * 1}px;
      line-height: ${Metric.fontSize * 2}px;
    }
  }

  @media (max-width: 480px) {
    .items {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 15px;
      margin-bottom: 50px;
      .item {
        width: 100%;
        height: 150px;
        padding: 15px;

        h4 {
          bottom: 10px;
        }
      }
    }

    .texto {
      grid-template-columns: 1fr;
      grid-row-gap: 40px;
    }
  }
`;
