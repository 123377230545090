import React, { useState, useEffect } from "react"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import api from "../../services/api"
import ImageGallery from 'react-image-gallery';
import { FaWhatsapp } from "react-icons/fa";

import { Loader } from "../../styles/global"
import { Container, ExameWrapper } from "./styles"
// Senhor dai-me paciência

export default function Exame(props) {
  const [exame, setExame] = useState()
  const [loading, setLoading] = useState(true)
  const [exameContent, setExameContent] = useState();
  const [exameImages, setExameImages] = useState([]);
  const [exameImagesValue, setExameImagesValue] = useState(false);


  useEffect(()=>{
    const slug = props.match.params.slug;
    const getData = async() => {
      const {data} = await api.get(`/wp/v2/exames?slug=${slug}`);

      data.forEach(item => (
        !!item.acf.galeria_de_imagens && item.acf.galeria_de_imagens.map(imgs => {
          setExameImages(exameImages => [...exameImages, {original: imgs.url, thumbnail: imgs.sizes.url}]);
          setExameImagesValue(true);
          return null;
        })
      ))

    } 
    getData();
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (props.match.params.slug) {
      getExame().then(() => {
        setLoading(false)
      })
    }// eslint-disable-next-line
  }, [])

  const getExame = async () => {
    const slug = props.match.params.slug
    const response = await api.get(`/wp/v2/exames?slug=${slug}`)

    if (response.data) {
      setExame(response.data)
      setExameContent(response.data[0]);
      document.title = `${response.data[0].title.rendered} - MKClinic`
    }
  }

  return (
    <>
      <Header />
      <ExameWrapper>
        {loading ? (
          <Loader size={100} />
        ) : (
            <Container>
              <div className='row'>
                <h1>Descrição</h1>
                <div dangerouslySetInnerHTML={{ __html: exameContent.acf.descricao }} />
              </div>
              <div className='row'>
                <h1>Objetivo</h1>
                <div dangerouslySetInnerHTML={{ __html: exameContent.acf.objetivo }} />
              </div>
              <div className='row'>
                <h1>Como Preparar</h1>
                <div dangerouslySetInnerHTML={{ __html: exameContent.acf.comoPreparar }} />
              </div>
              <div className='row'>
                <h3>Pode ser realizado em:</h3>
                <ul>
                  {!!exame && exame.map((obj) => (
                    obj.acf.clinicas.map((clinica, index) => (
                      <li key={index}>{clinica}</li>
                    ))
                  ))}
                </ul>
              </div>
              {exameImagesValue ? <ImageGallery items={exameImages} /> : null}
              <a href="https://api.whatsapp.com/send?phone=5542991390550&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20o%20exame%20" target="__blank">
                Solicite esse exame via WhatsApp <FaWhatsapp color="#fff"/>
              </a>
              {/* <Link to={`${exame.title}`}></Link> */}
            </Container>
          )}
      </ExameWrapper>
      <Footer darker={true} />
    </>
  )
}

// export default Exame;
