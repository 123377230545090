import styled from "styled-components";
import Color from "../../styles/colors";
// import Metric from "../../styles/metrics";

export const FooterWrapper = styled.footer`
  background-color: #414042;
  padding-top: 25px;
  /* margin-bottom: 25px; */
  width: 100%;
  height: 352px;


  .columns-holder {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    @media screen and (max-width: 480px) {
      flex-direction: column;
    }

    > div {
      margin-right: 70px;

      .button{
        margin-top: 40px;
        &:last-of-type{
          margin-bottom: 30px;
        }
      a{
        border: 2px solid gray;

        background: #fff;
        padding: 10px;
        color: gray;
        transition: all ease 0.4s;
        :hover{
          color:red;
        }

      }

      }

      @media screen and (max-width: 480px) {
        margin: 5px auto 14px auto;
      }

      .texto {
        margin-top: 10px;

        span {
          font-weight: bold;
          &#telefone{
            font-size: 17px;
          }
        }

        p {
          margin-top: 3px;
        }
      }
    }

    h4 {
      color: white;
      font-weight: bold;
      font-size: 21px;

      @media screen and (max-width: 480px) {
        margin-top: 10px;
      }
    }

    p {
      color: white;
      font-size: 14px;

      &#telefone{
        font-size: 17px;
      }
    }
  }
  a {
    margin: 15px auto auto auto;
  }
  .logo {
    width: 200px;
  }

  @media (max-width: 480px) {
    padding: 1px 5% 15px 5%;
    flex-direction: column;
    text-align: center;
    height: auto;

    .logo {
      height: 50px;
    }
  }
`;

export const EndFooter = styled.div`
  height: 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: 480px) {
    grid-template-columns: inherit;
    grid-gap: 20px;
    height: 181px;
    margin-top: 20px;
  }

  .text-responsible {
    width: 300px;
    text-align: center;
    align-self: center;
    justify-self: center;
    p {
      font-size: 14px;
    }
  }
  .socials {
    align-self: center;
    justify-self: center;
    a {
      margin: 5px;
      img {
        width: 35px;
        transition: all ease-in-out 0.3s;
        :hover {
          transform: scale(1.2);
        }
      }
    }
  }
  .copyright {
    margin: auto 50px;
    width: 260px;
    color: #000;
    align-self: center;
    justify-self: center;
    text-align: center;
    img {
      position: relative;
      top: 5px;
      animation: heartbeat 1.5s infinite linear;
      @keyframes heartbeat {
        0% {
          transform: scale(0.75);
        }
        30% {
          transform: scale(1);
        }
        50% {
          transform: scale(0.75);
        }
        70% {
          transform: scale(1);
        }
        90% {
          transform: scale(0.75);
        }
        100% {
          transform: scale(0.75);
        }
      }
    }
    a {
      color: ${Color.dourado};
      padding: 5px;
      margin-left: -5px;
      border-radius: 15px;
      z-index: -1;
      &:hover {
        background-color: ${Color.dourado};
        color: white;
      }
    }
  }
`;
