import React from "react";

import { BannerSliderWrapper, Slide } from "./styles";

const BannerSlider = ({ banners }) => {
  var settings = {
    dots: true,
    infinite: true,
    fade: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: function(i) {
      return <span>0{i + 1}</span>;
    }
  };
  return (
    <BannerSliderWrapper {...settings}>
      {banners &&
        banners.map((banner, index) => (
          <Slide key={index} image={banner.imagem.url}>
            <div className="img">
              <div className="text">
                <h2>{banner.titulo}</h2>
                {!!banner.subtitulo ? <h3>{banner.subtitulo}</h3> : ""}
              </div>
            </div>
          </Slide>
        ))}
    </BannerSliderWrapper>
  );
};

export default BannerSlider;
