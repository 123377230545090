import React from "react";

import { SobreTabsWrapper } from "./styles";

import icon_calculadora from "../../assets/img/icon_calculadora.svg";
import icon_convenios from "../../assets/img/icon_convenios.svg";
import icon_exames from "../../assets/img/icon_exames.svg";
import icon_ultrassom from "../../assets/img/icon_ultrassom.svg";

class SobreTabs extends React.Component {
  setTabActive = e => {
    const elem = e.target;
    if (elem.className === "item") {
      const tab = document.getElementById(elem.dataset.tab);
      const activeTab = document.querySelector("#tabs .items .item.active");
      const activeTabContent = document.querySelector("#tabs .texto.active");
      elem.classList.add("active");
      tab.classList.add("active");
      activeTab.classList.remove("active");
      activeTabContent.classList.remove("active");
    }
  };
  render() {
    return (
      <SobreTabsWrapper>
        {window.innerWidth <= 480 ? (
          <div className="items">
            <a data-scroll href="/#exames">
              <div
                className="item"
                data-tab="textoExames"
              // onMouseOver={this.setTabActive}
              >
                <div className="icon">
                  <img src={icon_exames} alt="" />
                </div>
                <h4>Exames</h4>
              </div>
            </a>

            <a data-scroll href="/calculadora-de-gestacao">
              <div
                className="item"
                data-tab="textoCalculadora"
              // onMouseOver={this.setTabActive}
              >
                <div className="icon">
                  <img src={icon_calculadora} alt="" />
                </div>
                <h4>Calculadora de Gestação</h4>
              </div>
            </a>

            <a data-scroll href="/exame/ultrassom-5d-live">
              <div
                className="item"
                data-tab="textoUltrassom"
                onMouseOver={this.setTabActive}
              >
                <div className="icon">
                  <img src={icon_ultrassom} alt="" />
                </div>
                <h4>Ultrassom 5D LIVE</h4>
              </div>
            </a>

            <a data-scroll href="/#convenios">
              <div
                className="item"
                data-tab="textoConvenios"
              // onMouseOver={this.setTabActive}
              >
                <div className="icon">
                  <img src={icon_convenios} alt="" />
                </div>
                <h4>Convênios</h4>
              </div>
            </a>
          </div>
        ) : (
            <div className="items">
              <a data-scroll href="/#exames">
                <div
                  className="item"
                  data-tab="textoExames"
                // onMouseOver={this.setTabActive}
                >
                  <div className="icon">
                    <img src={icon_exames} alt="" />
                  </div>
                  <h4>Exames</h4>
                </div>
              </a>

              <a data-scroll href="/calculadora-de-gestacao">
                <div
                  className="item"
                  data-tab="textoCalculadora"
                // onMouseOver={this.setTabActive}
                >
                  <div className="icon">
                    <img src={icon_calculadora} alt="" />
                  </div>
                  <h4>Calculadora da Gestação</h4>
                </div>
              </a>

              <a data-scroll href="/exame/ultrassom-5d-live">
                <div
                  className="item"
                  data-tab="textoUltrassom"
                // onMouseOver={this.setTabActive}
                >
                  <div className="icon">
                    <img src={icon_ultrassom} alt="" />
                  </div>
                  <h4>Ultrassom 5D LIVE</h4>
                </div>
              </a>

              <a data-scroll href="/#convenios">
                <div
                  className="item"
                  data-tab="textoConvenios"
                // onMouseOver={this.setTabActive}
                >
                  <div className="icon">
                    <img src={icon_convenios} alt="" />
                  </div>
                  <h4>Convênios</h4>
                </div>
              </a>
            </div>
          )}
        {/* <div id="textoBlock">
          <div className="texto active" id="textoExames">
            <h3>{tab_exames.titulo}</h3>
            <p>{tab_exames.texto}</p>
          </div>
          <div className="texto" id="textoCalculadora">
            <h3>{tab_calculadoraGestacao.titulo}</h3>
            <p>{tab_calculadoraGestacao.texto}</p>
          </div>
          <div className="texto" id="textoUltrassom">
            <h3>{tab_ultrassom4d.titulo}</h3>
            <p>{tab_ultrassom4d.texto}</p>
          </div>
          <div className="texto" id="textoConvenios">
            <h3>{tab_convenios.titulo}</h3>
            <p>{tab_convenios.texto}</p>
          </div>
        </div> */}
      </SobreTabsWrapper>
    );
  }
}

export default SobreTabs;
