import React, { useState, useEffect } from "react"

import Header from "../../components/Header"
import Footer from "../../components/Footer"

import * as Yup from "yup"
import "@fortawesome/fontawesome-free/css/all.min.css"

import api from "../../services/api"
import ademail from "../../services/ademail"

import {
  Container,
  AgendarWrapper,
  FormWrapper,
  UnformInput,
  UnformSelect,
  StyledUnformMaskedInput
} from "./styles"

import "react-datepicker/dist/react-datepicker.css"

const JunteSe = (props) => {

  const foneMask = [
    "(",
    /\d/,
    /\d/,
    ")",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ]

  const [selectedExame, setSelectedExame] = useState()
  const [selectorOptions, setSelectorOptions] = useState([])
  const [examesList, setExamesList] = useState([])
  const [dia, setDia] = useState(new Date())
  const [formState, setFormState] = useState("ready")
  const [formMessage, setFormMessage] = useState()

  const handleChangeFileInput = () => {
    const file = document.getElementById("file").files
    if (!!file) {
      document.getElementById("fileLabel").innerHTML = file[0].name
    }
  }

  const getExames = async () => {
    const response = await api.get("/wp/v2/exames")
    if (response.data) {
      const _exames = response.data
      var _options = []

      if (_exames) {
        _exames.map((exame) => {
          _options.push({
            id: exame.title.rendered,
            title: exame.title.rendered
          })
          return true
        })
        console.log(_options)
        setSelectorOptions([{ id: "Administrativo", title: "Administrativo" }])
      }
    }
  }

  const handleSubmit = async (data) => {
    if (examesList.length <= 0) {
      //checa se algum exame foi adicionado
      alert("É necessário selecionar ao menos um exame da lista!")
    } else {
      const hoje = new Date()

      if (dia <= hoje) {
        //se a data selecionada for hoje ou menor
        alert("A data pro exame não pode ser a data de hoje ou anterior")
      } else {
        setFormState("sending")

        const attach = document.getElementById("file").files[0]

        const body = `<p>Nome: ${data.nome}</p>
        <p>Email: ${data.email}</p>
        <p>Telefone: ${data.telefone}</p>
        <p>Celular: ${data.celular}</p>
        <p>Data de Nascimento: ${data.dataNascimento}</p>
        <p>Convênio: ${data.convenio}</p>
        <p>Exames: ${examesList}</p>
        <p>Data Solicidada: ${dia.toLocaleDateString("pt-Br")} - ${
          data.hora
          }</p>
        <p>Observações: ${data.observacoes}</p>`

        const mail = new FormData()
        mail.append("to", "web@agenciaade.com.br")
        mail.append("from", data.email)
        mail.append("as", data.nome)
        mail.append("subject", "Nova solicitação de agendamento - MkClinic")
        mail.append("message", body)
        if (!!attach) mail.append("attach", attach)

        const config = {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "content-type": "multipart/form-data"
          }
        }

        try {
          const response = await ademail.post("/email", mail, config)
          console.log()
          if (response) {
            setFormMessage(
              "Enviado! Entraremos em contato em breve para confirmar seu agendamento."
            )
            setFormState("sent")
          }
        } catch (err) {
          setFormMessage(
            "Algo deu errado. Revise as informações e tente novamente."
          )
          setFormState("error")
        }
      }
    }
  }

  const handleSelectorChange = (e) => {
    setSelectedExame(e.target.value)
  }

  useEffect(() => {
    document.title = "Junte-se a nós - MKClinic"
    getExames()

    if (props.match.params.exame) {
      const exame = props.match.params.exame
      setExamesList([exame])
    }
  }, [props.match.params.exame])

  const schema = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório"),
    telefone: Yup.string().required("Campo obrigatório"),
    dataNascimento: Yup.string().required("Campo obrigatório"),
    hora: Yup.string().required("Campo obrigatório"),
    email: Yup.string()
      .email("Insira um endereço de e-mail válido")
      .required("Campo obrigatório")
  })

  return (
    <>
      <Header />
      <AgendarWrapper>
        <Container>
          <h2>Participe de nossa equipe médica</h2>
          <FormWrapper schema={schema} onSubmit={handleSubmit}>
            <div className='row col-3'>
              <div className='input-field'>
                <UnformInput name='nome' label='Nome Completo*' />
              </div>
              <div className='input-field'>
                <UnformInput name='email' label='E-mail*' margin='normal' />
              </div>
              <div className='input-field'>
                <StyledUnformMaskedInput
                  mask={foneMask}
                  className='defaultInput'
                  name='celular'
                  label='Celular'
                  margin='normal'
                />
              </div>
            </div>
            <div className='row col-2'>
              <div>
                <label htmlFor='exames'>Selecione a área*</label>
                <UnformSelect
                  name='examesSelector'
                  multiple='multiple'
                  placeholder='Selecione a área'
                  options={selectorOptions}
                  onChange={handleSelectorChange}
                />
              </div>
              <div className='fileInput'>
                <label htmlFor='file'>
                  <i className='fas fa-paperclip' />{" "}
                  <span id='fileLabel'>Anexe seu currículo*</span>
                </label>

                <input
                  name='anexo'
                  id='file'
                  type='file'
                  onChange={() => handleChangeFileInput()}
                  hidden
                />
              </div>
            </div>
            {formState !== "sent" ? (
              <button className='btnEnviar'>
                {formState === "sending" ? "Enviando..." : "Desejo ser um colaborador Mk"}
              </button>
            ) : (
                ""
              )}
            {formState !== "ready" && (
              <span
                className={`formMessage ${formState === "error" && "error"}`}
              >
                {formMessage}
              </span>
            )}
          </FormWrapper>
        </Container>

        <Container className="equipemedica">
          <h2>Seja um colaborador</h2>
          <FormWrapper schema={schema} onSubmit={handleSubmit}>
            <div className='row col-2'>
              <div className='input-field'>
                <UnformInput name='nome' label='Nome Completo*' />
              </div>
              <div className='input-field'>
                <UnformInput name='email' label='E-mail*' margin='normal' />
              </div>
            </div>
            <div className='row col-3'>
              <div className='input-field'>
                <StyledUnformMaskedInput
                  mask={foneMask}
                  className='defaultInput'
                  name='celular'
                  label='Celular*'
                  margin='normal'
                />
              </div>
              <div className='input-field'>
                <UnformInput label='CRM' name='crm' />
              </div>
              <div className='fileInput'>
                <label htmlFor='file'>
                  <i className='fas fa-paperclip' />{" "}
                  <span id='fileLabel'>Anexe seu currículo*</span>
                </label>

                <input
                  name='anexo'
                  id='file'
                  type='file'
                  onChange={() => handleChangeFileInput()}
                  hidden
                />
              </div>
            </div>
            {formState !== "sent" ? (
              <button className='btnEnviar'>
                {formState === "sending" ? "Enviando..." : "Quero ser um médico parceiro"}
              </button>
            ) : (
                ""
              )}
            {formState !== "ready" && (
              <span
                className={`formMessage ${formState === "error" && "error"}`}
              >
                {formMessage}
              </span>
            )}
          </FormWrapper>
        </Container>
      </AgendarWrapper>
      <Footer darker={true} />
    </>
  )
}

export default JunteSe
