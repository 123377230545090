import styled from "styled-components";
import Color from "../../styles/colors";
import Metric from "../../styles/metrics";

import { Form, Input } from "@rocketseat/unform";

import bg_meritos from "../../assets/img/bg_meritos.jpg";
import bg_cta from "../../assets/img/bg_cta.jpg";

export const Container = styled.div`
  width: 75%;
  max-width: 1280px;
  margin: auto;


  .slick-arrow {
    top: 105%;
    &:before {
      border-radius: 100%;
      transition: 0.5s ease;
      font-weight: 300;
      font-size: ${Metric.base * 3}px;
      color: ${Color.dourado};
    }

    &.slick-next {
      right: 60px;
    }
    &.slick-prev {
      right: 100px;
      left: auto;
    }
  }


  @media (max-width: 480px) {
    width: 90%;
  }
`;

export const Sobre = styled.section`
  margin: ${Metric.base * 16}px 0;
  /* min-height: 600px; */

  #tabs{
    padding: 0 124px;
    @media only screen and (max-width: 480px) {
    padding: inherit;
  }
  }
  @media only screen and (max-width: 480px) {
    margin: ${Metric.base * 6}px 0;
  }
`;

export const IconConvenio = styled.div`
  width: 160px !important;
  /* border: 1px solid ${Color.cinzaLighter}; */
  border-radius: 100%;
  height: 160px;
  margin-bottom: 60px;
  position: relative;
  margin: 20px;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 150px;
    max-height: 120px;
  }
  `;

export const Meritos = styled.section`
  background-image: url('${bg_meritos}');
  background-size: cover;
  background-position: center;
  padding: ${Metric.base * 12}px 0;

  .icons{
      width: 75%;
      margin: auto;
      display: grid;
      grid-template-columns: repeat(4,1fr);
      grid-column-gap: ${Metric.base * 7.5}px;
      align-items: center;
      justify-content: space-around;


      .icon{
          display: flex;
          flex-direction: column;
          align-content: center;
          img{
              margin-bottom: ${Metric.base * 3}px;
          }
          h4{
              color: white;
              text-align: center;
              padding: 0 20%;
              font-size: ${Metric.fontSize * 1.1}px;
              font-family: "Volte3";
          }

      }
  }

  @media (max-width: 480px){
    padding: ${Metric.base * 5}px 0;

    .icons{
      grid-template-columns: repeat(2,1fr);
      grid-column-gap: 15px;
      grid-row-gap: 20px;

      .icon{
        img{
          max-height: 100px;
          margin-bottom: 10px;
        }
        h4{
          font-size: ${Metric.fontSize};
        }
      }


    }
  }
`;

export const CTA = styled.div`
  background-color: ${Color.dourado};
  padding: 80px 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  height: auto;

  &:before{
    position: absolute;
    z-index: -1;
    content: '';
    width: 65%;
    height: 100%;
    background-image: url('${bg_cta}');
    background: #414042 !important;
    top: 0;
    right: 0;
    overflow: hidden;
  }

.center-content{
  display: flex;
  margin-bottom: 10px;
  @media screen and (max-width: 480px){
    margin-bottom: 20px;
    display: block;
  }
  .grid-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media screen and (max-width: 480px){
   grid-template-columns: inherit;
   justify-content: center;
  }

    .block{
      margin: 10px;
      img{
        width: 180px;
        height: 120px;
        border-radius: 10px;
        object-fit: cover;
        transition: all ease 0.1s;

        :hover{
          transform:scale(1.1);
        }
      }
    }  
  }
  p{
        margin-left: 20px;
        color: #fff;
        line-height: 30px;
        @media screen and (max-width: 480px){
   text-align: center;
   margin: 20px;

  }
    }

}

  h3 {
    color: white;
    font-size: 65px;
    font-family: "Volte3";
    margin-bottom: 20px;
  }

  a{
    border-radius: ${Metric.borderRadius}px;
    background-color: white;
    color: ${Color.cinzaDark};
    font-size: ${Metric.fontSize * 0.9}px;
    font-family: "Volte3";
    padding: 10px 40px;
    transition: .5s ease;
    @media screen and (max-width: 480px){
    margin: 0 auto;
  }
    &:hover{
      color: white;
      background-color: ${Color.cinzaDark};
    }
  }

  @media (max-width: 480px){
    padding: ${Metric.base * 5}px 15%;
    height: auto;

    &:before{
      height: inherit;
    }
    h3{
      font-size: ${Metric.fontSize * 2}px;
      line-height: ${Metric.fontSize * 2}px;
      margin: 10px auto;   
    }
  }
`;
export const Blog = styled.section`
  background-color: ${Color.preto};
  padding: ${Metric.base * 10}px 0;

  h2 {
    font-size: ${Metric.fontSize * 1.5}px;
    color: white;
    margin-bottom: ${Metric.base * 6}px;
  }
  .posts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 50px;
  }

  .rowVerTodos {
    padding-top: 100px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;

    a {
      background-color: ${Color.dourado};
      color: white;
      padding: 20px;
      border-radius: 30px;
      transition: 0.5s ease;
      font-family: "Volte3";
      &:hover {
        color: ${Color.preto};
        background-color: white;
      }
    }
  }
  @media (max-width: 480px) {
    .posts {
      grid-template-columns: 1fr;
    }
    .rowVerTodos {
      padding-top: 0px;
    }
  }
`;

export const Post = styled.div`
  width: 100%;
  min-height: 450px;
  position: relative;
  cursor: pointer;
  
  .image{
    background-image: url('${props => props.thumb}');
    background-size: cover;
    background-position: center;
    height: 200px;
    width: 100%;
    border-radius: ${Metric.borderRadius}px;
  }

  .info{
    padding: ${Metric.base * 2}px;
    h5{
      margin-bottom: ${Metric.base * 2}px;
      font-size:${Metric.fontSize * 1.1}px;
      color: white;
      font-family:"Volte2";
    }
    p{
      color: ${Color.dourado};
      font-size: ${Metric.fontSize * 0.9}px;
      font-family: "Volte2";
      
    }
    span{
      color: white;
      font-weight: 700;
      position: absolute;
      bottom: 0;
      transition: .5s ease;
      padding: 10px;
      left: 10px;
      border-radius: 20px;
      i{
        margin: 0 10px;
        position: relative;
        top: 2px;
      }

      &:hover{
        background-color: white;
        color: ${Color.preto};
      }
    }
  }

  &:hover{
    span{
      background-color: white;
        color: ${Color.preto};
    }
  }

  @media (max-width: 480px){
    margin-bottom: 50px;
    /* height: 350px; */

    .info{
      h5{
        font-size:${Metric.fontSize * 1.1}px;
      }
      p{

        font-size:${Metric.fontSize}px;
      }
    }
  }
`;

export const Convenios = styled.section`
  padding: ${Metric.base * 10}px 0;
  height: 950px;

  h2 {
    text-align: center;
    font-size: ${Metric.fontSize * 1.5}px;
    color: ${Color.cinza};
    margin-bottom: ${Metric.base * 6}px;
  }

  /* @media screen and (max-width: 480px) {
    .slick-slide {
      width: inherit !important;
    }
    .slick-slider {
      height: 40px !important;
    }
  } */
`;

export const Contato = styled.section`
  background-color: #f8f8f8;
  padding: ${Metric.base * 10}px 0;

  .cols {
    display: grid;
  }

  button {
    position: relative;
    margin-top: 100px;
    float: right;
    background-color: ${Color.cinzaDark};
    border: none;
    padding: 0 30px;
    align-items: center;
    height: 40px;
    display: flex;
    color: white;
    font-family: "Volte3";
    transition: 0.5s ease;
    cursor: pointer;
    justify-content: center;
    &:hover {
      background-color: ${Color.dourado};
    }
  }

  @media (max-width: 480px) {
    padding: 50px 0;
    .cols {
      grid-template-columns: 1fr;
      form {
        margin-bottom: 50px;
      }

      .maps {
        grid-template-columns: 1fr;
        .map {
          margin-bottom: 50px;
          iframe {
            height: 200px;
          }
        }
      }
    }
    button {
      width: 100%;
    }
  }
`;

export const Unform = styled(Form)`
  h2 {
    font-size: ${Metric.fontSize * 1.5}px;
    font-family: "Volte2";
    color: ${Color.cinzaDark};
    margin-bottom: ${Metric.base * 5}px;
  }

  .input-field {
    label {
      color: ${Color.cinzaLighter};
      font-weight: 600;
      display: block;
    }
  }
`;

export const UnformTextInput = styled(Input)`
  width: 100%;
  height: 50px;
  background: none !important;
  border: none;
  border-bottom: 2px solid ${Color.cinzaDark};
  color: ${Color.cinzaDark};
  font-family: "Volte2";
  margin-bottom: 30px;

  &.multiline {
    height: 130px;
  }
`;
