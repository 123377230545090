import React, { Fragment } from 'react';
import { HashRouter, BrowserRouter } from 'react-router-dom';

import {GlobalStyle} from './styles/global';

import Routes from './routes';

const Router = process.env.REACT_APP_SERVER_ENV === 'development' ? HashRouter : BrowserRouter;

const App = () =>(
    <Router>
        <Fragment>
            <GlobalStyle  />
            <Routes  />
        </Fragment>
    </Router>
);

export default App;