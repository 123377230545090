

const width = window.innerWidth;
const Metric = responsiveMetrics(width, this);

function responsiveMetrics(width, Metric) {
  if (width > 480) {
    Metric = {
      base: 10,
      fontSize: 20,
      borderRadius: 20
    };
  } else {
    Metric = {
      base: 10,
      fontSize: 15,
      borderRadius: 20
    };
  }
  return Metric;
}

export default Metric;
