import styled, { createGlobalStyle } from "styled-components";
import "./fonts.css";
import "normalize.css";
import Color from "./colors";
// import Metric from "./metrics";

import { FaSpinner } from "react-icons/fa";

export const GlobalStyle = createGlobalStyle`

    html, body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 100%;

        button.slick-arrow.slick-next{
            display:none !important;
        }

        .ant-btn{
            border-radius: 50px;
          :hover{
          color: red;
          border-color: red;
        }
        :focus{
          color: red;
          border-color: red;
        }
      }
        }
        #root{
            overflow-y: hidden;
        }
        body {
            box-sizing: border-box;
            overflow-x: hidden;
            &.lock{
                overflow-y: hidden;
            }
        }

        * {
            outline: none;
            margin: 0;

            padding: 0;
            font-weight: 300;
            box-sizing: border-box;
            
        }

        ul li{
            list-style: none;
        }

        *::selection {
            color: #fff;
            background: ${Color.dourado};
        }

        :root{

            font-family: "Volte";
        }
        
        a{
            text-decoration: none;
            transition: .5s ease;
            border-radius: 50px;
        }


        h1, h2, h3, h4, h5, h6 {
            margin-top: 0;
        }

        button{
            border-radius: 50px;
        }



`;

export const Loader = styled(FaSpinner)`
  animation: spin 2s infinite ease;
  width: 100%;
  margin: 10% 0;

  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
