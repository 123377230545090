import styled from "styled-components"
import Color from "../../styles/colors"
import Metric from "../../styles/metrics"

export const Container = styled.div`
  width: 75%;
  max-width: 1280px;
  margin: auto;

  @media (max-width: 480px) {
    width: 90%;
  }
`

export const ExamesWrapper = styled.section`
  padding: ${Metric.base * 10}px 0;
  background-color: #f8f8f8;
  min-height: 100vh;
  h2 {
    color: ${Color.dourado};
    text-align: center;
    font-family: "Volte2";
    font-size: ${Metric.fontSize * 1.5}px;
    margin-bottom: ${Metric.base * 6}px;
  }

  .msg {
    width: 100%;
    text-align: center;
    font-weight: "Volte3";
    margin-bottom: 100px;
  }

  .busca {
    /* display: grid; */
    width: 50%;
    margin: 0 auto;
    background-color: white;
    grid-template-columns: 1fr 120px;
    margin-bottom: ${Metric.base * 10}px;
    box-shadow: 0 2px 20px 0 rgba(237, 237, 237, 0.5);
    align-items: center;
    height: ${Metric.base * 12}px;
    .search {
      /* border-right: 1px solid #e6e6e6; */
      padding: 30px;
      display: flex;
      align-items: center;
      &:hover {
        input {
          border-color: ${Color.dourado};
        }
        i {
          color: ${Color.dourado};
        }
      }
      i {
        transition: 0.5s ease;
        font-size: 30px;
        margin-right: 15px;
        color: ${Color.cinzaLighter};
      }
      input {
        transition: 0.5s ease;
        width: 100%;
        border: none;
        border-bottom: 1px solid #e6e6e6;
        height: ${Metric.base * 5}px;
      }
    }
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${Color.dourado};
      height: 100%;
      cursor: pointer;
      transition: 0.5s ease;
      span {
        color: white;
        font-family: "Volte2";
      }
      &:hover {
        background-color: ${Color.douradoDarker};
      }
    }
  }

  .listExames {
    a {
      cursor: pointer;
    }
    li {
      box-shadow: 0 2px 20px 0 rgba(237, 237, 237, 0.5);
      border-radius: 30px;
      background-color: white;
      border: 3px solid white;
      margin-bottom: 20px;
      height: 60px;
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition: 0.5s ease;
      &:hover {
        border-color: ${Color.cinza};
        .button {
          background-color: ${Color.cinza};
          color: white;
        }
      }
      .titulo {
        padding-left: 50px;
        color: ${Color.cinza};
        font-family: "Volte2";
        font-size: ${Metric.fontSize * 0.9}px;
        line-height: 20px;
      }

      .button {
        transition: 0.5s ease;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: ${Color.dourado};
        color: white;
        height: 100%;
        width: 200px;
        padding: 0 30px;
        border-radius: 30px;
        width: ${Metric.base * 20}px;
        font-family: "Volte3";
      }
    }
  }

  .paginator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    li {
      height: 20px;
      width: 20px;
      background-color: ${Color.dourado};
      color: white;
      font-size: 15px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Volte3";
      margin: 0 5px;
      transition: 0.5s ease;
      cursor: pointer;
      a {
        color: white;
      }
      &:hover {
        background-color: gray;
      }
    }
  }

  @media (max-width: 480px) {
    .busca {
      width: 100%;
      height: 90px;
      .search {
        padding: 10px;
      }
    }

    .listExames {
      li {
        height: 75px;
        .titulo {
          max-width: 60%;
          padding-left: 0;
          padding-right: 5px;
        }
        .button {
          width: 100px;
          padding: 0 15px;
          font-size: ${Metric.fontSize * 0.7}px;
          line-height: ${Metric.fontSize * 0.7}px;
        }
      }
    }
  }
`
