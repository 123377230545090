import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Pagination from "react-js-pagination"

import { ExamesWrapper, Container } from "./styles"

const Exames = ({ data }) => {
  const [exames] = useState(data)

  const [searchStr, setSearchStr] = useState("")
  const [examesFiltrados, setExamesFiltrados] = useState()
  const [pages, setPages] = useState()
  const [activePage, setActivePage] = useState(1)

  const itemsPerPage = 4 //qtd de itens por pagina (só trocar aqui!)

  useEffect(() => {
    recalcPages()
  }, [])

  function recalcPages() {
    //recalcula a quantidade de paginas no paginator
    var qtdPaginas
    if (examesFiltrados) {
      qtdPaginas = examesFiltrados.length / itemsPerPage //qtd de elementos por pagina
    } else {
      qtdPaginas = exames.length / itemsPerPage //qtd de elementos por pagina
    }

    setPages(Math.ceil(qtdPaginas)) //arredonda a quantidade de paginas pra cima
  }

  const handleChangeExameFilter = (e) => {
    const str = e.target.value
    setSearchStr(str)

    if (str.length > 0) {
      //checa se o value nao é vazio
      const lowerStr = str.toLowerCase()
      const filter = exames.filter(
        //busca ou pelo titulo ou pelo slug!
        (exame) =>
          //converte o titulo e busca pra lowercase p/ padronizar a busca
          exame.title.rendered
            .toLowerCase() //converte o titulo pra lowercase
            .indexOf(lowerStr) !== -1 || exame.slug.indexOf(lowerStr) !== -1 //se >=0 quer dizer que a search contem no titulo, entao true
      )
      if (filter) {
        setExamesFiltrados(filter)
      }
    } else {
      setExamesFiltrados(null)
    }
    recalcPages()
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
  }

  return (
    <ExamesWrapper >
      <Container>
        <h2>Exames</h2>
        <div className='busca'>
          <div className='search'>
            <i className='fas fa-search' />
            <input
              type='text'
              placeholder={"Busque seu exame"}
              value={searchStr}
              onChange={handleChangeExameFilter}
            />
          </div>
          {/* <div className="button" onClick={filterExames}>
                  <span>Filtrar</span>
                </div> */}
        </div>

        {!!examesFiltrados ? (
          examesFiltrados.length > 0 ? (
            <ul className='listExames'>
              {examesFiltrados.map((exame) => (
                <Link key={exame.id} to={`/exame/${exame.slug}`}>
                  <li>
                    <div className='titulo'>
                      {exame.title.rendered}<br />
                      <small>
                        {exame.acf.verificar_disponibilidade === true ? '(Verificar disponibilidade)' : '(Verificar disponibilidade)'}
                      </small>
                    </div>
                    <span className='button'>
                      <span>Ver mais</span>
                      <i className='fa fa-arrow-right' />
                    </span>
                  </li>
                </Link>
              ))}
            </ul>
          ) : (
              <div className='msg'>{`Sua busca por '${searchStr}' não retornou nenhum resultado.`}</div>
            )
        ) : (
            exames && (
              <ul className='listExames'>
                {exames
                  .slice(
                    (activePage - 1) * itemsPerPage,
                    activePage * itemsPerPage
                  )
                  .map((exame) => (
                    <Link key={exame.id} to={`/exame/${exame.slug}`}>
                      <li>
                        <div className='titulo'>
                          {exame.title.rendered}<br />
                          <small>
                            {exame.acf.verificar_disponibilidade === true ? '(Verificar disponibilidade)' : '(Verificar disponibilidade)'}
                          </small>
                        </div>
                        <span className='button'>
                          <span>Ver mais</span>
                          <i className='fa fa-arrow-right' />
                        </span>
                      </li>
                    </Link>
                  ))}
              </ul>
            )
          )}
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={
            !!examesFiltrados ? examesFiltrados.length : exames.length
          }
          innerClass='paginator'
          pageRangeDisplayed={pages}
          onChange={handlePageChange}
        />
      </Container>
    </ExamesWrapper>
  )
}

export default Exames
