import styled from "styled-components";
import Color from "../../styles/colors";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import texture_banner from "../../assets/img/texture_banner.jpg";

export const BannerSliderWrapper = styled(Slider)`
  margin-bottom: 100px;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  height: 75vh;
  position: relative;

  .slick-dots {
    bottom: 30px;
    left: -45%;

    li {
      font-family: "Volte3";
      color: ${Color.cinza};
      cursor: pointer;
      transition: 0.5s ease;

      &:hover {
        background-color: ${Color.dourado};
        color: white;
        &:before {
          content: none;
        }
      }
      &:before {
        width: 0%;
        position: absolute;
        height: 3px;
        background-color: ${Color.dourado};
        bottom: -2px;
        content: "";
        transition: 0.5s ease;
      }
      &.slick-active {
        &:before {
          width: 95%;
        }
      }
    }
  }

  @media (max-width: 480px) {
    height: 30vh;

    .slick-dots {
      right: 10px;
      left: inherit;
      width: fit-content;

      li {
        color: #fff;
      }
    }
  }
`;

export const Slide = styled.div`

width: 100%;
padding-left: 15%;
height: 75vh;
position: relative;
z-index: 0;
.img{
  width: 100%;
  height: 100%;
  background-image: url('${props => props.image}');
  background-size: cover;
  background-position: center;
}
.text{
  background-image: url('${texture_banner}');
  background-size: cover;
  background-position: center;
  background: #414042 !important;
  border-radius: 19px;
  z-index: 5;
  left: 0;
  width: 30%;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  align-items: flex-start;
  top: 50%;
  position: absolute;
  z-index: 20;
  h2{
    font-family: "Volte3";
    font-size:31px;
    line-height: 40px;
    color: white;

  }
  h3{
    font-family: "Volte2";
    font-size:16px;
    color: white;


  }
}

@media (max-width: 480px){

  height: 30vh;

  .text{
    width: 50%;
    height: 120px;
    top: 40%;
    padding: 25px 20px;

    h2{
      font-size:20px;
      margin: 0 0 10px 0;
      line-height: 16px;
    }
    h3{
      font-size:12px;
      margin: 0;
      line-height: 12px;
    }
  }

}
`;
