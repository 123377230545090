import React, { useState, useEffect } from "react";
import { format, parseISO, differenceInCalendarDays, addDays } from "date-fns";

// import addDays from "date-fns/addDays";
import pt from "date-fns/locale/pt";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import "@fortawesome/fontawesome-free/css/all.min.css";

import {
  Container,
  AgendarWrapper,
  FormWrapper,
  StyledUnformMaskedInput,
} from "./styles";

import "react-datepicker/dist/react-datepicker.css";

const Calculadora = (props) => {
  const dateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

  const [data, setData] = useState();
  const [newData, setNewData] = useState();
  const [remainData, setRemainData] = useState();
  const [daysWithBaby, setDaysWithBaby] = useState();
  const [resultGestacao, setResultGestacao] = useState(false);
  const [resultGestacaoError, setResultGestacaoError] = useState(false);

  function mostrarResultado(result, error) {
    setResultGestacao(result);
    setResultGestacaoError(error);

    document.body.classList.toggle("active");
  }

  const handleCalculoGestacao = () => {
    const dia = data[0] + data[1];
    const mes = data[3] + data[4];
    const ano = data[6] + data[7] + data[8] + data[9];
    const mData = parseISO(ano + "-" + mes + "-" + dia);
    const result = addDays(mData, 280);

    const formattedDate = format(result, "'dia' dd 'de' MMMM' de 'yyyy'", {
      locale: pt,
    });

    const currentDay = format(new Date(), "yyyy-MM-dd");
    const mCurrentDay = parseISO(currentDay);
    const daysRemaining = differenceInCalendarDays(result, mCurrentDay);
    const daysWithBaby = differenceInCalendarDays(mCurrentDay, mData);
    let boolResult, boolError;

    console.log(daysRemaining);

    if (daysRemaining < 0 || daysWithBaby < 0) {
      boolResult = false;
      boolError = true;
      return mostrarResultado(boolResult, boolError);
    } else {
      setDaysWithBaby([daysWithBaby]);
      setRemainData([daysRemaining]);
      setNewData([formattedDate]);

      boolResult = true;
      boolError = false;

      return mostrarResultado(boolResult, boolError);
    }
  };

  useEffect(() => {
    document.title = "Calculadora da Gestação | MKClinic";
  }, [props.match.params.exame]);

  return (
    <>
      <Header />
      <AgendarWrapper>
        <Container>
          <h2>Calculadora da Gestação</h2>
          <FormWrapper>
            <div className="row">
              <div className="input-field">
                <StyledUnformMaskedInput
                  className="defaultInput"
                  name="menstruacao"
                  mask={dateMask}
                  value={data}
                  onChange={(e) => setData(e.target.value)}
                  label="Quando foi o Primeiro dia da sua última menstruação?*"
                />
              </div>
            </div>
            <button
              type="submit"
              onClick={handleCalculoGestacao}
              className="btnEnviar"
            >
              Calcular
            </button>
          </FormWrapper>
          <div className={`result ${resultGestacao && "active"}`}>
            <p>- Data possível para o parto: {newData}</p>
            <p>
              - Se já confirmado o exame BetaHCG, falta {remainData} dias para o
              parto!
            </p>
            <p>
              - Se já confirmado o exame BetaHCG, você está grávida há{" "}
              {daysWithBaby} dias :)
            </p>
          </div>
          <div className={`result ${resultGestacaoError && "active"}`}>
            <p>Desculpe, ocorreu um erro, informe uma data válida!</p>
          </div>
        </Container>
      </AgendarWrapper>
      <Footer darker={true} />
    </>
  );
};

export default Calculadora;
