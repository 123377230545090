import '@fortawesome/fontawesome-free/css/all.min.css'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// import SmoothScroll from "smooth-scroll";
import { Button, Dropdown, Menu } from 'antd'

import logo from '../../assets/img/logo.svg'
import { HeaderWrapper, MenuMobile, Nav, NavFixed } from './styles'

const Header = () => {
  const [isNavFixed, setNavFixed] = useState(false)
  const [isMenuToggled, setMenuToggled] = useState(false)

  const menu = (
    <Menu>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://api.whatsapp.com/send?phone=5542991390550&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20o%20exame%20"
        >
          MkClinic Ponta Grossa
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://api.whatsapp.com/send?phone=5542991390550&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20o%20exame%20"
        >
          MKClinic Prime Ponta Grossa
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://api.whatsapp.com/send?phone=5542991390550&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20o%20exame%20"
        >
          MkClinic Jaguariaiva
        </a>
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    // const spyMain = new Gumshoe("#gumshoejs-main a");
    // const spySticky = new Gumshoe("#gumshoejs-sticky a");
    // const scroll = new SmoothScroll('a[href*="#"]');

    window.addEventListener('scroll', scrollIsNavFixed)
  }, [])

  const scrollIsNavFixed = () => {
    if (window.pageYOffset > 400) {
      setNavFixed(true)
    } else {
      setNavFixed(false)
    }
  }

  const toggleMenu = () => {
    if (isMenuToggled === false) {
      setMenuToggled(true)
    } else {
      setMenuToggled(false)
      document.querySelector('body').classList.remove('lock')
    }
  }

  return (
    <HeaderWrapper>
      <Nav id="main-nav">
        <div className="upper">
          <div className="content">
            <div className="left">
              <span>
                <strong>Seg a Sex |</strong> 08h às 12h e das 14h às 18h
              </span>
              <span>
                <strong>Ponta Grossa |</strong> (42) 3223-5005
              </span>
              <span>
                <strong>Jaguariaíva |</strong> (43) 3535-5598 e (43) 3535-5978
              </span>
            </div>
            <div className="right">
              <a
                href="https://www.facebook.com/MK-Clinic-Clinica-de-Radiologia-386170008468898/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../assets/img/facebook_icon.svg')}
                  alt="facebook mkclinic"
                />
              </a>
              <a
                href="https://www.instagram.com/mkclinicpg/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../assets/img/instagram_icon.svg')}
                  alt="instagram mkclinic"
                />
              </a>
            </div>
            <div />
          </div>
        </div>
        <div className="main" id="gumshoejs-main">
          <i className="menu-icon fas fa-bars" onClick={toggleMenu} />
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
          <div className="links">
            <ul>
              <li>
                <a href="/#exames">Exames</a>
              </li>
              <li>
                <Link to="/sobre/#sobre">Sobre</Link>
              </li>
              {/* <li>
                <Link to="/sobre/#corpoClinico">Corpo Clínico</Link>
              </li> */}
              {/* <li>
                <Link to="/blog">Blog</Link>
              </li> */}
              <li>
                <a href="/#convenios">Convênios</a>
              </li>
              <li>
                <Link to="/junte-se-a-nos">Junte-se a nós</Link>
              </li>
              <li>
                <a href="/#contato">Contato</a>
              </li>
              <Link to="/" className="button buttonConsulte hide">
                Consulte seu Exame
              </Link>

              <Dropdown overlay={menu} placement="bottomLeft">
                <Button>Agende seu Exame</Button>
              </Dropdown>
            </ul>
          </div>
        </div>
      </Nav>
      <NavFixed isNavFixed={isNavFixed} id="nav-sticky">
        <i className="menu-icon fas fa-bars" onClick={toggleMenu} />
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
        <div className="links" id="gumshoejs-sticky">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <a href="/#exames">Exames</a>
            </li>
            <li>
              <Link to="/sobre/#sobre">Sobre</Link>
            </li>
            {/* <li>
              <Link to="/sobre/#corpoClinico">Corpo Clínico</Link>
            </li> */}
            {/* <li>
              <Link to="/blog">Blog</Link>
            </li> */}
            <li>
              <a href="/#convenios">Convênios</a>
            </li>
            <li>
              <Link to="/junte-se-a-nos">Junte-se a nós</Link>
            </li>
            <li>
              <a href="/#contato">Contato</a>
            </li>
            <Link to="/" className="button buttonConsulte hide">
              Consulte seu Exame
            </Link>
            <Dropdown overlay={menu} placement="bottomLeft">
              <Button>Agende seu Exame</Button>
            </Dropdown>
          </ul>
        </div>
      </NavFixed>
      <MenuMobile isMenuToggled={isMenuToggled}>
        <div className="backdrop" />
        <div className="menu-wrapper">
          <div className="top">
            <i className="menu-icon fas fa-times" onClick={toggleMenu} />
          </div>
          <Link to="/">
            <img className="logo" src={logo} alt="" />
          </Link>
          <div className="links" id="gumshoejs-sticky">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              {/* <li>
                <Link to="/sobre/#corpoClinico">Corpo Clínico</Link>
              </li> */}
              <li>
                <a href="/#exames">Exames</a>
              </li>
              <li>
                <Link to="/sobre/#sobre">Sobre</Link>
              </li>
              {/* <li>
                <Link to="/blog">Blog</Link>
              </li> */}
              <li>
                <a href="/#convenios">Convênios</a>
              </li>
              <li>
                <Link to="/junte-se-a-nos">Junte-se a nós</Link>
              </li>
              <li>
                <a href="/#contato">Contato</a>
              </li>
              <Dropdown overlay={menu} placement="bottomLeft">
                <Button>Agende seu Exame</Button>
              </Dropdown>
            </ul>
          </div>
        </div>
      </MenuMobile>
    </HeaderWrapper>
  )
}

export default Header
