import React, { useState, useEffect } from "react"

import Header from "../../components/Header"
import Footer from "../../components/Footer"
import { MdClose } from "react-icons/md"

import produce from "immer"
import * as Yup from "yup"
import "@fortawesome/fontawesome-free/css/all.min.css"

import api from "../../services/api"
import ademail from "../../services/ademail"

import {
  Container,
  AgendarWrapper,
  FormWrapper,
  UnformInput,
  UnformSelect,
  StyledUnformMaskedInput
} from "./styles"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const Agendar = (props) => {
  const timeMask = [/\d/, /\d/, ":", /\d/, /\d/]
  const dateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]

  const foneMask = [
    "(",
    /\d/,
    /\d/,
    ")",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ]

  const [selectedExame, setSelectedExame] = useState()
  const [selectorOptions, setSelectorOptions] = useState([])
  const [examesList, setExamesList] = useState([])
  const [dia, setDia] = useState(new Date())
  const [formState, setFormState] = useState("ready")
  const [formMessage, setFormMessage] = useState()

  const handleChangeFileInput = () => {
    const file = document.getElementById("file").files
    if (!!file) {
      document.getElementById("fileLabel").innerHTML = file[0].name
    }
  }

  const getExames = async () => {
    const response = await api.get("/wp/v2/exames")
    if (response.data) {
      const _exames = response.data
      var _options = []

      if (_exames) {
        _exames.map((exame) => {
          _options.push({
            id: exame.title.rendered,
            title: exame.title.rendered
          })
          return true
        })
        setSelectorOptions(_options)
      }
    }
  }

  const handleSubmit = async (data) => {
    if (examesList.length <= 0) {
      //checa se algum exame foi adicionado
      alert("É necessário selecionar ao menos um exame da lista!")
    } else {
      const hoje = new Date()

      if (dia <= hoje) {
        //se a data selecionada for hoje ou menor
        alert("A data pro exame não pode ser a data de hoje ou anterior")
      } else {
        setFormState("sending")

        const attach = document.getElementById("file").files[0]

        const body = `<p>Nome: ${data.nome}</p>
        <p>Email: ${data.email}</p>
        <p>Telefone: ${data.telefone}</p>
        <p>Celular: ${data.celular}</p>
        <p>Data de Nascimento: ${data.dataNascimento}</p>
        <p>Convênio: ${data.convenio}</p>
        <p>Exames: ${examesList}</p>
        <p>Data Solicidada: ${dia.toLocaleDateString("pt-Br")} - ${
          data.hora
        }</p>
        <p>Observações: ${data.observacoes}</p>`

        const mail = new FormData()
        mail.append("to", "web@agenciaade.com.br")
        mail.append("from", data.email)
        mail.append("as", data.nome)
        mail.append("subject", "Nova solicitação de agendamento - MkClinic")
        mail.append("message", body)
        if (!!attach) mail.append("attach", attach)

        const config = {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "content-type": "multipart/form-data"
          }
        }

        try {
          const response = await ademail.post("/email", mail, config)
          console.log()
          if (response) {
            setFormMessage(
              "Enviado! Entraremos em contato em breve para confirmar seu agendamento."
            )
            setFormState("sent")
          }
        } catch (err) {
          setFormMessage(
            "Algo deu errado. Revise as informações e tente novamente."
          )
          setFormState("error")
        }
      }
    }
  }

  const handleSelectorChange = (e) => {
    setSelectedExame(e.target.value)
  }

  const pushExameToList = (e) => {
    if (selectedExame) {
      const nextState = produce(examesList, (draft) => {
        const examIndex = draft.findIndex((d) => d === selectedExame) //busca se o exame já não está na lista

        if (examIndex < 0) {
          //se não estiver, adiciona na lista,
          draft.push(selectedExame)
        }
      })
      setExamesList(nextState) //seta novo estado
    }
  }

  function removeExameFromList(exame) {
    if (exame) {
      const nextState = produce(examesList, (draft) => {
        const examIndex = draft.findIndex((d) => d === exame)

        if (examIndex >= 0) {
          //se for encontrado na lista
          draft.splice(examIndex, 1) //busca no indice e remove
        }
      })
      setExamesList(nextState) //seta o novo estado
    }
  }

  const handleDataChange = (props) => {
    setDia(props)
  }

  useEffect(() => {
    document.title = "Agendar Exame - MKPrime"
    getExames()

    if (props.match.params.exame) {
      const exame = props.match.params.exame
      setExamesList([exame])
    }
  }, [])

  const schema = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório"),
    telefone: Yup.string().required("Campo obrigatório"),
    dataNascimento: Yup.string().required("Campo obrigatório"),
    hora: Yup.string().required("Campo obrigatório"),
    email: Yup.string()
      .email("Insira um endereço de e-mail válido")
      .required("Campo obrigatório")
  })

  return (
    <>
      <Header />
      <AgendarWrapper>
        <Container>
          <h1>Solicitar Exame</h1>
          <FormWrapper schema={schema} onSubmit={handleSubmit}>
            <div className='row col-2'>
              <div className='input-field'>
                <UnformInput name='nome' label='Seu nome Completo*' />
              </div>
              <div className='input-field'>
                <StyledUnformMaskedInput
                  className='defaultInput'
                  mask={foneMask}
                  name='telefone'
                  label='Telefone*'
                  margin='normal'
                />
              </div>
            </div>
            <div className='row col-3'>
              <div className='input-field'>
                <StyledUnformMaskedInput
                  mask={foneMask}
                  className='defaultInput'
                  name='celular'
                  label='Celular'
                  margin='normal'
                />
              </div>
              <div className='input-field'>
                <StyledUnformMaskedInput
                  className='defaultInput'
                  mask={dateMask}
                  name='dataNascimento'
                  label='Data de Nascimento*'
                  margin='normal'
                />
              </div>
              <div className='input-field'>
                <UnformInput name='email' label='E-mail*' margin='normal' />
              </div>
            </div>
            <div className='row col-3'>
              <div className='input-field'>
                <div className='input-field'>
                  <UnformInput label='Convênio' name='convenio' />
                </div>
              </div>
              <div className='input-field'>
                <UnformInput label='Médico Solicitante' name='medico' />
              </div>
              <div className='fileInput'>
                <label htmlFor='file'>
                  <i className='fas fa-paperclip' />{" "}
                  <span id='fileLabel'>Anexe um Arquivo</span>
                </label>

                <input
                  name='anexo'
                  id='file'
                  type='file'
                  onChange={() => handleChangeFileInput()}
                  hidden
                />
              </div>
            </div>
            <div className='exames'>
              <div>
                <label htmlFor='exames'>Selecione os Exames*</label>
                <UnformSelect
                  name='examesSelector'
                  multiple='multiple'
                  placeholder='Selecione os exames'
                  options={selectorOptions}
                  onChange={handleSelectorChange}
                />
                <span
                  href='#!'
                  className='btnAdicionar'
                  onClick={pushExameToList}
                >
                  Adicionar
                  <i className='fas fa-plus' />
                </span>
              </div>
              <div className='listSelecionados'>
                <label>
                  Exames Selecionados:
                  <br />
                  <small>
                    {examesList.length > 0 &&
                      " (clique no X para remover da lista)"}
                  </small>
                </label>
                {examesList.length > 0 ? (
                  <ul id='examesSelecionados' className='examesSelecionados'>
                    {examesList.map((exame, index) => (
                      <li key={index}>
                        {exame}

                        <MdClose
                          size={20}
                          onClick={() => removeExameFromList(exame)}
                        />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <>
                    <span>
                      Nenhum exame selecionado. <br />
                      Adicione exames pelo seletor{" "}
                      {window.innerWidth >= 480 ? "ao lado" : "acima"}.
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className='dataHora'>
              <div className='data'>
                <label htmlFor='datepicker'>
                  Data do Exame* <br />
                  (marcar com pelo menos 1 semana de atecedência)
                </label>
                <DatePicker
                  inline
                  dateFormat='Pp'
                  selected={dia}
                  onChange={handleDataChange}
                />
              </div>
              <div className='hora'>
                <label>Horário do Exame*</label>
                <div className='horaWrapper'>
                  <StyledUnformMaskedInput
                    mask={timeMask}
                    name='hora'
                    id='hora'
                    placeholder='00:00'
                  />
                </div>
                <UnformInput
                  className='observacoesInput'
                  label='Observações'
                  name='observacoes'
                  multiline
                />
              </div>
            </div>
            {formState !== "sent" ? (
              <button className='btnEnviar'>
                {formState === "sending" ? "Enviando..." : "Enviar"}
              </button>
            ) : (
              ""
            )}
            {formState !== "ready" && (
              <span
                className={`formMessage ${formState === "error" && "error"}`}
              >
                {formMessage}
              </span>
            )}
          </FormWrapper>
        </Container>
      </AgendarWrapper>
      <Footer darker={true} />
    </>
  )
}

export default Agendar
