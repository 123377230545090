import styled from "styled-components";

export const Container = styled.div`
  width: 65%;
  max-width: 1280px;
  margin: auto;

  @media screen and (max-width: 480px) {
    width: inherit;
  }

  .top-text {
    width: 500px;
    margin: 0 auto;
    text-align: center;

    @media screen and (max-width: 480px) {
      width: inherit;
      margin-top: 20px;
    }

    h1 {
      margin-bottom: 10px;
    }
    p {
      font-size: 30px;
      font-weight: bold;
    }
  }

  .doctor-info {
    display: flex;
    margin-top: 30px;
    margin-bottom: 50px;

    @media screen and (max-width: 480px) {
      display: inherit;
    }

    img {
      @media screen and (max-width: 480px) {
        margin: 30px;
      }
    }

    p {
      padding-left: 30px;
    }
  }
`;
